body.webapp-site {
    // tl custom tailwind
    @media screen and (min-width: 991px) {
        mat-stepper {
            &.stepper_full-step {
                div.mat-horizontal-stepper-wrapper, div.mat-horizontal-content-container, div.mat-horizontal-stepper-content {
                    height: 100%;
                    overflow: hidden;
                }
                .mat-horizontal-content-container {
                    width: 100%;
                    position: relative;
                    .mat-horizontal-stepper-content {
                        width: 100%;
                        position: absolute;
                        top: 0;
                        left: 0;
                        visibility: hidden;
                    }
                }
            }
        }
    }
    
    // md tailwind
    @media screen and (min-width: 768px) {
        swal2-popup.swal2-modal.alert_table-swal ctt_table table { width: 100%; }
    }

    // sm Tailwind
    @media screen and (min-width: 640px) {

    }

    @media screen and (min-width: 361px) and (max-width: 767px) {

    }

    @media screen and (min-width: 1024px) {
        // Tailwind lg
        &.hide__menu { 
           .hide__menu-desktop { grid-template-columns: minmax(0,0) minmax(0,1fr); }
        }
    }

    
    @media screen and (max-width: 991px){
        .table_responsive .dataTables_wrapper table.dataTable { width: 100% !important; }
    }

    @media screen and (max-width: 767px) {
        .table_responsive {
            .dataTables_wrapper table.dataTable tbody tr {
                &::after { transition: all ease-in-out .3s; }
                &.dt-hasChild {
                    &::after { width: 56%; }
                }
                &.child {
                    ul.dtr-details { width: 100%; display: flex; flex-flow: wrap; padding: 1rem; padding-top: 0;
                        li { display: grid; grid-template-columns: minmax(0, 50%) minmax(0, 50%); width: 100%;
                            .dtr-title, .dtr-data { width: 100%; align-self: center; }
                        }
                    }
                }
            }
        }
        
        .grid-sales{
            grid-template-columns: repeat(auto-fill, minmax(100%, 1fr));
            ul li{
                display: flex;
                flex-wrap: wrap;
                justify-content: center;
                width: 90%;
                margin: 0 auto;
            }
        }


    }

    @media screen and (max-width: 639px) {
        .table_responsive {
            .dataTables_wrapper table.dataTable tbody tr.child ul.dtr-details {
                li { grid-template-columns: minmax(0, 100%);
                    &:not(:last-child) { padding-bottom: 1rem; border-bottom: 1px solid #d4d4d4; }
                }
            }
        }
        .cdk-overlay-container .cdk-overlay-connected-position-bounding-box { background-color: rgba(0, 0, 0, 0.36); width: 100% !important; right: 0 !important; left: 0 !important;
            .cdk-overlay-pane { position: unset; margin: auto; }
        }
        .file-drop-content.type_2::before { padding-bottom: 80%; }

        .layout-card1{
            grid-template-columns: 1fr;
        }

        .item-cat{
            flex: 1 0 100%;
        }

        .lst-paralela{
            li{
                width: 100% !important;
            }
        }


    }


    @media screen and (max-width: 512px){
        .notifications{
            li{
                .ls-phone, .ls-date, .ls-socios, .ls-send, app-user-custom{
                    flex: 1 0 100%;

                    border-bottom: 1px solid #0a356333;
                    padding-bottom: 12px;
                    
                }

                app-user-custom{
                    margin: 0 auto;
                    .user-custom{
                        justify-content: center;
                    }
                }

                .ls-date{
                    flex-direction: row;
                    gap: 16px;
                }

                .ls-send{
                    border-bottom: 1px solid transparent !important;
                }
            }
        }

        .panel-header{
            justify-content: center;
        }

        .grid-2{
            display: flex;
            gap: 16px;
            flex-wrap: wrap;

            .item-grid2{
                flex: 1 0 135px;
            }
        }

        .grid-metas{
            grid-template-columns: repeat(auto-fill, minmax(285px, 1fr));
        }
    }


    @media screen and (min-width: 1350px) {
        .grid-panel{
            display: grid;
            grid-template-columns: repeat(6, minmax(0, 1fr));
            grid-gap: 1rem;
        }
    }

    @media screen and (min-width: 1536px) {
        .grid-panel{
            max-width: 1536px;
            margin: 0 auto;
        }
    }

}
