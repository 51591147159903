$primary-color: #2cc1ff;
$second-color: #0a3563;
$third-color: #8dd8f8;

$rgb-primary-color: 44, 193, 255; //--primary-color *
$rgb-second-color: 10, 53, 99; //--second-color *
$rgb-third-color: 141, 216, 248; //--third-color *

$error-color: #ff4a4a;
$warning-color: #ffd227;
$info-color: #4ac6ff;
$success-color: #40d565;

$font-title: "Oswald", sans-serif;
$font-subtext: "Inter", sans-serif;
$font-text: "Inter", sans-serif;

:root {
  --primary-color: #{$primary-color};
  --second-color: #{$second-color};
  --third-color: #{$third-color};
  // * for RGBA
  --rgb-primary-color: #{$rgb-primary-color}; //--primary-color *
  --rgb-second-color: #{$rgb-second-color}; //--second-color *
  --rgb-third-color: #{$rgb-third-color}; //--third-color *

  --error-color: #{$error-color};
  --warning-color: #{$warning-color};
  --info-color: #{$info-color};
  --success-color: #{$success-color};

  --rgb-error-color: #{red($error-color), green($error-color),
    blue($error-color)};
  --rgb-warning-color: #{red($warning-color), green($warning-color),
    blue($warning-color)};
  --rgb-info-color: #{red($info-color), green($info-color), blue($info-color)};
  --rgb-success-color: #{red($success-color), green($success-color),
    blue($success-color)};

  // * Font
  --font-title: #{$font-title};
  --font-subtext: #{$font-subtext};
  --font-text: #{$font-text};
}
