@import "../vars.scss"; // ruta del archivo de variables
@import "ngx-toastr/toastr";

/*
 * Tailwind custom
*/
.before-total {
  @apply before:absolute before:top-0 before:left-0 before:w-full before:h-[100%];
}
.overlay-total {
  @apply absolute top-0 left-0 w-full h-full;
}
.input_group {
  @apply relative w-full py-4;
  label {
    @apply text-base m-0 mb-1 block p-0;
  }
  input,
  select {
    border: 1px solid #f1f1f1;
    border-radius: 8px;
    line-height: normal;
    display: block;
    padding: 10px 15px;
    width: 100%;
    @apply text-base;
    &:focus {
      outline: 2px solid var(--primary-color);
      border: 1px solid #f1f1f1;
      box-shadow: none;
    }
  }
  .input_hide {
    @apply absolute top-1/2 right-2 -translate-y-1/2 text-base w-[32px] h-[32px] flex items-center justify-center rounded-full bg-white cursor-pointer;
  }
}
.hide_text-oneline {
  @apply whitespace-nowrap text-ellipsis overflow-hidden max-w-full;
}
.header-toolbar-apply {
  @apply bg-black bg-opacity-70 absolute top-0 left-0 w-full h-auto z-20;
}
.alert {
  @apply text-center rounded font-medium px-3 py-2 text-lg;
}
.alert-danger {
  @apply bg-red-200 text-red-500;
}
.alert-success {
  @apply bg-green-200 text-green-500;
}
.checkbox_toggle {
  .checkbox_toggle-content .checkbox_toggle-check {
    @apply translate-x-[5px];
    &.checked {
      @apply bg-green-400 translate-x-[30px];
    }
  }
}

[tooltip]:not([tooltip-desc="false"]) {
  position: relative;
  &::before {
    content: attr(tooltip);
    position: absolute;
    top: calc(5px + 100%);
    left: 50%;
    transform: translateX(-50%);
    width: auto;
    max-width: 110%;
    text-align: center;
  }
}
.btn-action {
  @apply py-2 px-3.5 rounded bg-gray-100 text-xs leading-none text-gray-600 inline-flex items-center justify-center gap-[0.25rem];
  &.success:not(.mdc-button) {
    @apply bg-emerald-50 text-emerald-400;
    &:hover {
      @apply bg-emerald-100;
    }
  }
  &.active:not(.mdc-button) {
    @apply bg-green-50 text-green-400;
    &:hover {
      @apply bg-green-100;
    }
  }
  &.danger:not(.mdc-button) {
    @apply bg-red-50 text-red-400;
    &:hover {
      @apply bg-red-100;
    }
  }
  &.warning:not(.mdc-button) {
    @apply bg-amber-50 text-amber-400;
    &:hover {
      @apply bg-amber-100;
    }
  }
  &.info:not(.mdc-button) {
    @apply bg-sky-50 text-sky-400;
    &:hover {
      @apply bg-sky-100;
    }
  }
  &.custom-1:not(.mdc-button) {
    @apply bg-blue-50 text-blue-400;
    &:hover {
      @apply bg-blue-100;
    }
  }
  &.mdc-button {
    height: auto;
    padding: 0;
    min-width: unset;
    @apply rounded;
    .mdc-button__label {
      display: inline-flex;
      align-items: center;
      gap: 0.5rem;
      font-weight: 400;
      line-height: normal;
      @apply py-2 px-3.5 text-xs;
    }
    &.success {
      @apply bg-emerald-50 text-emerald-400;
      &:hover {
        @apply bg-emerald-100;
      }
    }
    &.active {
      @apply bg-green-50 text-green-400;
      &:hover {
        @apply bg-green-100;
      }
    }
    &.danger {
      @apply bg-red-50 text-red-400;
      &:hover {
        @apply bg-red-100;
      }
    }
    &.warning {
      @apply bg-amber-50 text-amber-400;
      &:hover {
        @apply bg-amber-100;
      }
    }
    &.info {
      @apply bg-sky-50 text-sky-400;
      &:hover {
        @apply bg-sky-100;
      }
    }
    &.custom-1 {
      @apply bg-blue-50 text-blue-400;
      &:hover {
        @apply bg-blue-100;
      }
    }
    &.add-task {
      background-color: rgba(#bb004d, 0.2);
      color: #bb004d;
      &:hover {
        background-color: rgba(#bb004d, 0.4);
      }
    }
    &.add-activity {
      background-color: rgba(#53769c, 0.2);
      color: #53769c;
      &:hover {
        background-color: rgba(#53769c, 0.4);
      }
    }
  }
}
mat-paginator {
  --mat-paginator-container-text-size: 14px;
}
.mat-table,
.mat-menu-item {
  font-family: var(--font-subtext);
}
// .mat-cell, .mat-footer-cell { @apply 2xl:text-[13px] xl:text-[13px]; }
app-cap-datatable {
  mat-paginator {
    .mat-paginator-container {
      justify-content: space-between;
    }
  }
}
.pagination_table-out {
  width: auto;
  display: flex;
  flex-flow: wrap;
  gap: 0.5rem;
  list-style: none;
  .paginate_button {
    position: relative;
    display: block;
    .page-link {
      background: #f7f5f5;
      color: #818181;
      width: auto;
      min-width: 40px;
      height: 40px;
      line-height: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 10px;
      &.active {
        background: var(--primary-color);
        color: #fff;
      }
      &:hover:not(.active):not(:disabled):not(.disabled) {
        background: var(--primary-color);
        color: #fff;
        opacity: 0.9;
      }
      &.disabled,
      &:disabled {
        background-color: #f7f5f5;
        color: #c9c9c9;
        pointer-events: none;
      }
    }
  }
}
.input__color {
  padding: 0;
  -webkit-appearance: none;
  appearance: none;
  border: 0;
  height: 25px;
  width: auto;
  min-width: 45px;
  cursor: pointer;
  &::-webkit-color-swatch-wrapper {
    padding: 0;
  }
  &::-webkit-color-swatch {
    border: none;
    border-radius: 4px;
  }
}

.btn__back {
  display: block;
  --opacity: 1;
  --tw-bg-opacity: 1;
  --background: #000;
}

.loading_wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 999999999999999;
  .backdrop_loading {
    background-color: rgba(0, 0, 0, 0.3);
    z-index: 1;
    pointer-events: none;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .loading__content {
    display: block;
    width: auto;
    z-index: 2;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: #fff;
    padding: 1.5rem 2rem;
    border-radius: 15px;
    p {
      display: block;
      margin: 0;
      text-align: center;
      width: 100%;
      font-weight: 500;
      font-size: 16px;
    }
  }
}

// Loading Default
.loads-ellipsis {
  margin: 0 auto;
  display: block;
  position: relative;
  width: 80px;
  height: 80px;
  div {
    position: absolute;
    top: 33px;
    width: 13px;
    height: 13px;
    border-radius: 50%;
    background: var(--primary-color);
    animation-timing-function: cubic-bezier(0, 1, 1, 0);
    &:nth-child(1) {
      left: 8px;
      animation: lds-ellipsis1 0.6s infinite;
    }
    &:nth-child(2) {
      left: 8px;
      animation: lds-ellipsis2 0.6s infinite;
    }
    &:nth-child(3) {
      left: 32px;
      animation: lds-ellipsis2 0.6s infinite;
    }
    &:nth-child(4) {
      left: 56px;
      animation: lds-ellipsis3 0.6s infinite;
    }
  }
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(24px, 0);
  }
}

// Loading 2
.loadingio-spinner-dual-ball {
  width: 100px;
  height: 100px;
  display: inline-block;
  overflow: hidden;
  background: transparent;
}
.ldio-dualball div {
  position: absolute;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  top: 30px;
  left: 10px;
}
.ldio-dualball div:nth-child(1) {
  background: #e90c59;
  animation: ldio-dualball 1s linear infinite;
  animation-delay: -0.5s;
}
.ldio-dualball div:nth-child(2) {
  background: #46dff0;
  animation: ldio-dualball 1s linear infinite;
  animation-delay: 0s;
}
.ldio-dualball div:nth-child(3) {
  background: #e90c59;
  animation: ldio-dualball-o 1s linear infinite;
  animation-delay: -0.5s;
}
.ldio-dualball {
  width: 100%;
  height: 100%;
  position: relative;
  transform: translateZ(0) scale(1);
  backface-visibility: hidden;
  transform-origin: 0 0;
}
.ldio-dualball div {
  box-sizing: content-box;
}
@keyframes ldio-dualball-o {
  0% {
    opacity: 1;
    transform: translate(0 0);
  }
  49.99% {
    opacity: 1;
    transform: translate(40px, 0);
  }
  50% {
    opacity: 0;
    transform: translate(40px, 0);
  }
  100% {
    opacity: 0;
    transform: translate(0, 0);
  }
}
@keyframes ldio-dualball {
  0% {
    transform: translate(0, 0);
  }
  50% {
    transform: translate(40px, 0);
  }
  100% {
    transform: translate(0, 0);
  }
}

// Loading 3
.loading-roller {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
  div {
    animation: loading_roaller 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    transform-origin: 40px 40px;
    &:after {
      content: " ";
      display: block;
      position: absolute;
      width: 7px;
      height: 7px;
      border-radius: 50%;
      background: var(--third-color);
      margin: -4px 0 0 -4px;
    }
    &:nth-child(1) {
      animation-delay: -0.036s;
      &:after {
        top: 63px;
        left: 63px;
      }
    }
    &:nth-child(2) {
      animation-delay: -0.072s;
      &:after {
        top: 68px;
        left: 56px;
      }
    }
    &:nth-child(3) {
      animation-delay: -0.108s;
      &:after {
        top: 71px;
        left: 48px;
      }
    }
    &:nth-child(4) {
      animation-delay: -0.144s;
      &:after {
        top: 72px;
        left: 40px;
      }
    }
    &:nth-child(5) {
      animation-delay: -0.18s;
      &:after {
        top: 71px;
        left: 32px;
      }
    }
    &:nth-child(6) {
      animation-delay: -0.216s;
      &:after {
        top: 68px;
        left: 24px;
      }
    }
    &:nth-child(7) {
      animation-delay: -0.252s;
      &:after {
        top: 63px;
        left: 17px;
      }
    }
    &:nth-child(8) {
      animation-delay: -0.288s;
      &:after {
        top: 56px;
        left: 12px;
      }
    }
  }
}
@keyframes loading_roaller {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

body.webapp-site {
  position: relative;
  .content_scroll-site {
    min-height: 100%;
    width: 100%;
    display: grid;
    grid-template-columns: minmax(0, 1fr);
    grid-template-rows: 1fr max-content;
    overflow: auto;
    max-width: 100%;
    max-height: 100%;
    &.not__scroll {
      grid-template-rows: minmax(0, 1fr) minmax(0, max-content);
    }
  }
  input {
    @apply focus:ring-0;
  }
  &.swal2-height-auto {
    height: 100% !important;
  }
  .file-drop-content {
    width: 100%;
    height: auto;
    display: block;
    position: relative;
    border: 1px solid #ddd;
    border-radius: 14px;
    border-style: dashed;
    border-width: medium;
    transition: all ease-in-out 0.26s;
    &:hover {
      background-color: #f7f7f7;
    }
    &::before {
      content: "";
      display: block;
      padding-bottom: 60%;
    }
    &.type_2::before {
      padding-bottom: 38%;
    }
    input {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      opacity: 0;
      z-index: 2;
      cursor: pointer;
    }
    .description-file-after {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: max-content;
      height: auto;
      max-width: 100%;
      padding: 1rem;
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 1rem;
      flex-flow: column;
      span {
        display: block;
        text-align: center;
        font-size: 14px;
        i {
          font-size: 82px;
        }
      }
    }
  }
  .content_file {
    display: flex;
    flex-flow: column;
    align-items: center;
    justify-content: center;
    gap: 1rem;
    span {
      display: block;
      padding: 1rem 1.5rem;
      background-color: #f5f5f5;
      border-radius: 8px;
      width: auto;
      max-width: 100%;
      cursor: default;
      font-size: 15px;
      font-weight: 500 !important;
      color: #5754a3 !important;
    }
    .content_options {
      display: inline-flex;
      gap: 1rem;
      flex-flow: wrap;
      margin-top: 1rem;
      button {
        appearance: none;
        display: inline-flex;
        align-items: center;
        justify-content: center;
        padding: 8px 15px;
        border: 0;
        border-radius: 6px;
        font-size: 16px;
        &.file-btn_clear {
          background: #e76363;
          color: #fff;
          &:hover {
            background: #d53c3c;
          }
        }
        &.file-btn_submit {
          background: #3dc65f;
          color: #fff;
          &:hover:not(:disabled) {
            background: #2cb14d;
          }
          &:disabled {
            background-color: #b5b5c3;
          }
        }
      }
    }
  }
  .style_b-notifications:not(:last-child) .item_b-style-notification {
    @apply before:w-[1px] before:bg-gray-300 before:h-[calc(100%_+_1rem)] before:absolute before:top-[calc(100%_+_2px)] before:-translate-y-1/2 before:right-[calc(100%_-_10.8rem)] before:translate-x-1/2 before:z-[-1];
  }
  .toast-container {
    padding: 10px;
    max-width: 100%;
    &.toast-top-right {
      top: 0;
      right: 0;
    }
    &.toast-top-left {
      top: 0;
      left: 0;
    }
    &.toast-bottom-right {
      bottom: 0;
      right: 0;
    }
    &.toast-bottom-left {
      bottom: 0;
      left: 0;
    }
    div[custom-toast-component],
    .ngx-toastr {
      border: 0;
      max-width: 100%;
      background: transparent !important;
      @apply shadow-none mt-0 ml-0 mr-0 mb-2 p-0 overflow-auto drop-shadow-[0_3px_5px_rgba(100,100,100,0.5)];
      &.toast-error {
        @apply text-gray-700;
        .toaster {
          @apply bg-red-100;
          .toast-icon {
            @apply bg-red-400;
          }
        }
        .close-icon {
          @apply text-red-400;
        }
      }
      &.toast-success {
        @apply text-gray-700;
        .toaster {
          @apply bg-green-100;
          .toast-icon {
            @apply bg-green-400;
          }
        }
        .close-icon {
          @apply text-green-400;
        }
      }
      &.toast-info {
        @apply text-gray-700;
        .toaster {
          @apply bg-sky-100;
          .toast-icon {
            @apply bg-sky-400;
          }
        }
        .close-icon {
          @apply text-sky-400;
        }
      }
      &.toast-warning {
        @apply text-gray-700;
        .toaster {
          @apply bg-yellow-100;
          .toast-icon {
            @apply bg-yellow-400;
          }
        }
        .close-icon {
          @apply text-yellow-400;
        }
      }
      .toaster {
        @apply grid grid-cols-[minmax(0,50px)_minmax(0,1fr)_minmax(0,25px)] pr-2 text-white bg-[#333333] w-[340px] max-w-full min-h-[80px] rounded-lg shadow-none m-0 overflow-hidden;
        .toast-icon {
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: 26px;
          @apply row-span-2;
        }
        .toast-details {
          @apply text-base p-4 text-left;
        }
        .close-icon {
          @apply self-center;
          a {
            color: currentColor;
            @apply relative top-[unset] bottom-[unset] left-[unset] right-[unset] block m-0 w-full text-center float-none;
          }
        }
        .toast-details {
          @apply text-gray-700;
          .toast-title {
            @apply text-base font-semibold;
          }
          .toast-message {
            @apply text-sm;
          }
        }
        .progress-content {
          @apply col-start-2 col-end-3 relative block w-full;
        }
      }
    }
  }
  .content__scroll-sidebar {
    &::-webkit-scrollbar {
      width: 8px;
      height: 8px;
    }
    &::-webkit-scrollbar-track {
      background: #f1f1f1;
      border-radius: 1rem;
    }
    &::-webkit-scrollbar-thumb {
      background: #888;
      border-radius: 1rem;
    }
    &::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
  }
  .toast-right-top,
  .toast-left-top,
  .toast-right-bottom,
  .toast-left-bottom {
    height: auto;
  }
  .swal2-container {
    .swal2-popup {
      .swal2-icon {
        /* width: 4em; height: 4em; */
        .swal2-icon-content {
          font-size: 2.75em;
        }
      }
      .swal2-title {
        font-size: 22px;
      }
      .swal2-html-container {
        font-size: 16px;
      }
      .swal2-actions {
        .swal2-cancel {
          -webkit-box-ordinal-group: 1;
          -ms-flex-order: 0;
          order: 0;
        }
        .swal2-confirm {
          background-color: var(--primary-color);
          -webkit-box-ordinal-group: 2;
          -ms-flex-order: 1;
          order: 1;
          &:focus {
            box-shadow: 0 0 0 3px rgba(var(--primary-color), 0.5);
          }
        }
        .swal2-styled {
          font-size: 16px;
        }
      }
    }
  }
  table:not(.custom-table-design):not(.mat-calendar-table) {
    border-radius: 12px;
    width: 100%;
    margin-bottom: 1rem;
    @apply overflow-hidden;
    &.shadow {
      @apply drop-shadow;
    }
    thead th {
      border: 0;
      background-color: #fff;
      color: #000;
      font-weight: 600;
      font-size: 15px;
    }
    tbody tr {
      &:not(.no-border) {
        position: relative;
        &:not(:last-child) {
          &:after {
            content: "";
            position: absolute;
            left: 50%;
            bottom: 0;
            transform: translateX(-50%);
            width: 100%;
            height: 1px;
            background-color: rgb(241, 241, 241);
          }
        }
      }
      td {
        font-size: 14px;
        padding-block: 4px;
      }
    }
  }
  .hide__menu-desktop {
    transition: all ease-in-out 0.5s;
  }
  .text-two-hidden {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    overflow: hidden;
  }
  app-sidebar,
  app-sidebar-documentation,
  button-cy {
    display: contents;
  }
  .sidebar_left {
    &.active {
      transform: translateX(0);
    }
  }
  .mat-expansion-panel-content {
    font-family: var(--font-subtext);
  }
  mat-expansion-panel-header.btn_a-sidebar {
    font-family: var(--font-subtext);
    height: auto;
    .mat-content {
      @apply grid grid-cols-[minmax(0,35px)_minmax(0,1fr)_minmax(0,35px)] gap-2;
    }
  }
  .btn_a-sidebar {
    span {
      color: var(--second-color);
      font-weight: 300;
    }
    &.active {
      background-color: var(--second-color);
      i {
        color: var(--primary-color);
      }
      span {
        color: #fff;
        font-weight: 400;
      }
      &::before {
        display: none;
      }
    }
  }
  .btn_a-sidebar-doc {
    &.active {
      @apply bg-emerald-50 text-emerald-600 font-medium;
    }
  }
  .btn_tab-request {
    &.active {
      background-color: #fff;
    }
  }
  .method-post {
    @apply bg-sky-600;
  }
  .method-get {
    @apply bg-emerald-600;
  }
  app-dynamic-form-field {
    display: contents;
  }
  app-root {
    display: block;
    height: 100%;
  }
  .mat-menu-content {
    padding: 0;
  }
  .mat-dialog-container {
    padding: 0;
    border-radius: 22px;
    .header_modal {
      padding: 1rem 1.5rem;
    }
    .content_modal {
      padding: 2rem 2.5rem;
      .content__wrapper {
        h5 {
          display: block;
          line-height: normal;
          width: 100%;
          margin: 0 0 1rem;
          font-size: 16px;
          strong {
            color: var(--primary-color);
            font-weight: 700;
          }
          span {
            color: var(--second-color);
            font-weight: 600;
          }
        }
        ul {
          padding-left: 2rem;
          list-style: none;
          display: grid;
          grid-template-columns: 100%;
          gap: 1rem;
          li {
            width: 100%;
            display: block;
            font-size: 14px;
            strong {
              margin-right: 5px;
            }
          }
        }
        &.note {
          p {
            font-weight: 400;
            font-size: 14px;
            line-height: 17px;
            a {
              color: var(--third-color);
              cursor: pointer;
              font-weight: 600;
              &:hover {
                color: var(--second-color);
              }
            }
          }
        }
      }
    }
    .actions_modal {
      padding: 1rem 1.5rem;
    }
  }
  .btn-asset.active {
    @apply bg-green-50;
  }
  .dataTable_custom {
    max-width: 100%;
    th {
      text-align: left;
    }
  }
  .table_custom {
    th {
      text-align: left;
    }
  }
  .table_responsive {
    .dataTables_wrapper {
      .dataTables_length,
      .dataTables_filter {
        margin-bottom: 1rem;
      }
      .dataTables_filter {
        float: left;
        margin-left: 20px;
      }
      table.dataTable {
        border-collapse: collapse;
        min-width: 100%;
        &.no-footer {
          border: 0;
        }
        thead {
          // .sorting { background-image: url('../../img/icons/dataTables/up-down-disabled.svg'); background-size: 15px; background-repeat: no-repeat; /* background-position: calc(100% - 5px) 50%; */ background-position: calc(0% + 5px) 50%; padding: 10px; padding-left: 26px;
          //     &::before, &::after { display: none; }
          //     &.sorting_asc { background-image: url('../../img/icons/dataTables/up.svg') !important; }
          //     &.sorting_desc { background-image: url('../../img/icons/dataTables/down.svg') !important; }
          // }
          .sorting {
            padding: 5px;
            background: none;
            &::before {
              display: none;
            }
            &::after {
              content: "";
              display: inline-block;
              width: 30px;
              height: 100%;
              min-height: 25px;
              position: unset;
              transform: none;
              opacity: 0.8;
              vertical-align: middle;
              background-image: url("../../img/icons/dataTables/up-down-disabled.svg");
              background-size: 15px;
              background-repeat: no-repeat; /* background-position: calc(100% - 5px) 50%; */
              background-position: calc(0% + 5px) 50%;
            }
            &.sorting_asc::after {
              background-image: url("../../img/icons/dataTables/up.svg") !important;
            }
            &.sorting_desc::after {
              background-image: url("../../img/icons/dataTables/down.svg") !important;
            }
          }
        }
        tbody tr {
          &.child ul.dtr-details {
            display: inline-flex;
            gap: 1rem;
            align-items: flex-start;
            flex-flow: wrap;
            li {
              display: inline-block;
              padding: 0;
            }
          }
        }
        .dt-hasChild {
          @apply bg-gray-100;
          & ~ tr.child {
            @apply bg-gray-100;
          }
        }
        &.dtr-inline.collapsed > tbody > tr {
          & > {
            td,
            th {
              &:first-child:not(.child) {
                padding-left: 40px;
                &::before {
                  background-color: var(--second-color) !important;
                  width: 20px;
                  height: 20px;
                  display: flex;
                  align-items: center;
                  justify-content: center;
                  font-size: 24px;
                  font-weight: 700;
                  border-radius: 50%;
                  top: 50%;
                  transform: translateY(-50%);
                  margin: 0;
                }
              }
            }
          }
        }
      }
      select,
      .dataTables_filter input {
        color: var(--primary-color);
        padding-left: 1rem;
        padding-right: 1rem;
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        line-height: 1.25;
        border-width: 2px;
        border-color: #edf2f7;
        background: #fff;
        border-radius: 10px;
      }

      .dataTables_paginate {
        padding-top: 0.755em;
        display: flex;
        flex-flow: wrap;
        gap: 2px;

        .paginate_button {
          font-weight: 700;
          border-radius: 0.25rem;
          border: 1px solid transparent;
          margin: 0;
          @apply text-sm;

          &.current {
            color: #fff !important;
            box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1),
              0 1px 2px 0 rgba(0, 0, 0, 0.06);
            font-weight: 700;
            border-radius: 0.25rem;
            background: var(--primary-color) !important;
            border: 1px solid transparent;
          }

          &:hover:not(.disabled):not(.current) {
            color: #fff !important;
            box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1),
              0 1px 2px 0 rgba(0, 0, 0, 0.06);
            font-weight: 700;
            border-radius: 0.25rem;
            background: var(--second-color) !important;
            border: 1px solid transparent;
          }

          &.disabled {
            // color: #fff !important;
            // box-shadow: 0 1px 3px 0 rgba(0, 0, 0, .1), 0 1px 2px 0 rgba(0, 0, 0, .06);
            font-weight: 500;
            border-radius: 0.25rem;
            border: 1px solid transparent;
            // @apply bg-gray-400;
          }
        }

        span:not(.ellipsis) {
          display: flex;
          flex-flow: wrap;
          gap: 2px;
        }
      }
    }
    // table.dataTable.hover tbody tr:hover, table.dataTable.display tbody tr:hover { background-color: #ebf4ff; }
    &.type_small {
      table.dataTable {
        @apply drop-shadow-none;
      }
      .dataTables_length,
      .dataTables_filter {
        width: 100%;
        float: none;
        display: block;
      }
      .dataTables_filter {
        label {
          display: flex;
          width: 100%;
          align-items: center;
          gap: 1rem;
          input {
            width: 100%;
            margin: 0;
            display: block;
          }
        }
      }
      .dataTables_paginate {
        float: none;
        display: flex;
        flex-flow: wrap;
        width: 100%;
        justify-content: center;
        align-items: center;
        span:not(.ellipsis) {
          display: block; /* width: 100%; */
          display: flex;
          gap: 3px;
          align-items: center;
          justify-content: center;
          a {
            margin: 0;
          }
        }
        .paginate_button {
          padding: 0.5em 0.8em;
        }
      }
    }
  }
  .custom-content-doc {
    @apply block text-sm text-gray-800 py-4;
    a {
      color: var(--primary-color);
      &:hover {
        color: var(--second-color);
        text-decoration: underline;
      }
    }
    h3 {
      @apply text-xl text-gray-600 font-medium mt-6 mb-4;
    }
    h4 {
      @apply text-lg text-gray-600 font-medium mt-4 mb-3;
    }
    h5 {
      @apply text-base text-gray-600 font-medium mt-4 mb-3;
    }
    ul {
      @apply list-disc pl-8 text-sm flex flex-col gap-2 w-full;
    }
    .table_responsive {
      @apply relative overflow-x-auto shadow-md sm:rounded-lg;
      table {
        @apply w-full text-sm text-left text-gray-500;
        thead {
          @apply text-xs text-gray-700 uppercase bg-gray-50;
          th {
            @apply px-6 py-3;
          }
        }
        tbody {
          tr {
            @apply border-b odd:bg-white even:bg-gray-50;
            td {
              @apply px-6 py-4;
              code {
                @apply bg-gray-200 rounded text-gray-600;
                span {
                  @apply inline-block p-2 leading-none;
                }
              }
            }
          }
        }
      }
    }
    blockquote {
      @apply bg-[#f6f8fa] text-gray-600 text-sm flex flex-col w-full shadow-md sm:rounded-lg p-0;
      .content-block {
        @apply w-full grid md:grid-cols-[minmax(0,1fr)_minmax(0,min-content)] grid-cols-[minmax(0,1fr)] items-center p-4;
        &:not(:last-child) {
          @apply border-b;
        }
      }
      .content-block-left {
        @apply w-full flex flex-col gap-2;
      }
      .content-block-header {
        @apply w-full flex flex-wrap gap-2 items-center;
        b {
          @apply text-sm font-bold text-gray-700;
        }
        span {
          @apply text-xs font-medium text-gray-400;
        }
        a {
          @apply text-xs font-medium text-red-500;
        }
      }
      .content-block-right {
        @apply w-auto block;
        input {
          @apply block text-center py-2 px-4 bg-white rounded;
        }
      }
      .content-block-status {
        @apply w-full block p-4;
        &:not(:last-child) {
          @apply border-b;
        }
        .status {
          @apply bg-gray-300 rounded-full w-[20px] h-[20px] shadow-sm;
          &.success {
            @apply bg-gradient-to-t from-emerald-500 via-green-500 to-green-400;
          }
          &.error {
            @apply bg-gradient-to-t from-red-600 via-red-500 to-red-400;
          }
        }
      }
    }
  }
  .content-pre_code {
    background: #f6f8fa;
    overflow: hidden;
    position: relative;
    @apply sm:rounded-lg shadow-md p-6;
    .code_pre,
    div {
      display: inherit;
    }
    .code-copy {
      cursor: pointer;
      z-index: 2;
      position: absolute;
      top: 6px;
      right: 6px;
      width: 40px;
      height: 40px;
      text-align: center;
      background-color: #f6f8fa;
      border-radius: 4px;
      color: #5d5d5d;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 18px;
      &:hover {
        background-color: #e0e0e0;
      }
    }
  }
  .select_2-des {
    @apply w-full block;
    .select2-selection {
      @apply min-h-full block m-0 w-full h-auto bg-slate-50 border-0 rounded py-2 px-4 text-base;
      .select2-selection__rendered {
        padding: 0;
        @apply text-base;
      }
      .select2-selection__arrow {
        @apply top-1/2 -translate-y-1/2;
      }
    }
  }
  .ngx-json-viewer .segment .segment-main .segment-key {
    color: var(--third-color) !important;
  }
  .ngx-json-viewer .segment-type-string > .segment-main > .segment-value {
    color: #d06909 !important;
  }
  .ngx-json-viewer .segment-type-null > .segment-main > .segment-value {
    padding: 1px 5px;
    border-radius: 4px;
    background-color: #944ffd !important;
  }
  .ngx-json-viewer .segment-type-undefined > .segment-main > .segment-key {
    color: #999 !important;
  }
  .ngx-json-viewer .segment-type-undefined > .segment-main > .segment-value {
    padding: 1px 5px;
    border-radius: 4px;
  }

  .dp-popup {
    background: transparent;
    box-shadow: none;
    border: 0;
    dp-day-calendar {
      background: #fff;
      padding: 1rem 0;
      border-radius: 8px;
      box-shadow: 0 2px 8px rgba(0, 0, 0, 0.12);
      dp-calendar-nav {
        width: 100%;
        display: block;
        .dp-calendar-nav-container {
          background-color: #fff;
          border: 0;
          padding: 0 1rem 0.5rem;
          height: auto;
          width: 100%;
          display: grid;
          grid-template-columns: minmax(0, 1fr) minmax(0, min-content);
          align-items: center;
          .dp-nav-header {
            position: unset;
            left: unset;
            top: unset;
            transform: none;
            font-size: 16px;
            font-weight: 600;
            .dp-nav-header-btn {
              font-weight: inherit;
              width: auto;
              height: auto;
              padding: 0.5rem 1rem;
            }
          }
          .dp-nav-btns-container {
            position: unset;
            right: unset;
            top: unset;
            transform: none;
            display: flex;
            align-items: center;
            gap: 8px;
            .dp-current-location-btn {
              top: unset;
            }
            .dp-calendar-nav-container-left,
            .dp-calendar-nav-container-right {
              button {
                display: flex;
                align-items: center;
                justify-content: center;
                height: 30px;
                width: 30px;
                text-align: center;
                background: transparent;
                color: #00b462;
                border-radius: 50%;
                &:hover {
                  background-color: #f1f1f1;
                }
                &::before {
                  height: 10px;
                  width: 10px;
                  border-color: currentColor;
                }
              }
            }
          }
        }
      }
      .dp-calendar-wrapper {
        border: 0;
        .dp-weekdays {
          margin: 0;
          margin-bottom: 0.5rem;
          padding: 0.5rem 1rem;
          border-top: 0;
          border-right: 0;
          border-left: 0;
          border-bottom: 1px solid #dedede;
          display: grid;
          grid-template-columns: repeat(7, minmax(0, 1fr));
          gap: 10px;
          .dp-calendar-weekday {
            border: 0 !important;
          }
        }
        .dp-calendar-week {
          width: 100%;
          display: grid;
          grid-template-columns: repeat(7, minmax(0, 1fr));
          gap: 10px;
          text-align: center;
          padding: 0.25rem 1rem;
          font-size: 13px;
          font-weight: 500;
          .dp-calendar-day {
            font-weight: inherit;
          }
          .dp-prev-month,
          .dp-next-month {
            opacity: 0.3;
          }
        }
      }
    }
  }
  mat-calendar {
    --mat-datepicker-calendar-text-size: 12px;
  }
  .input__date {
    &.type_full {
      .input__date-item {
        box-sizing: border-box;
        @apply block m-0 w-full bg-slate-50 rounded py-2 px-4 text-base border-none shadow-none focus:border-[#F1F1F1] focus:ring-0;
      }
      mat-datepicker-toggle {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        transform: none;
        button {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
          border-radius: 0;
          svg {
            position: absolute;
            top: 50%;
            right: 10px;
            transform: translateY(-50%);
            width: 25px;
            height: 25px;
            display: flex;
            align-items: center;
            justify-content: center;
          }
          .mat-mdc-button-persistent-ripple {
            border-radius: 0;
          }
        }
      }
    }
    &.type__only {
      position: relative;
      border-radius: 6px;
      overflow: hidden;
      input {
        width: 100%;
        border-color: rgba(241, 241, 241, 1);
        border-radius: 6px;
        background-color: #fff;
      }
      mat-datepicker-toggle {
        border-radius: 6px;
        overflow: hidden;
      }
    }
  }
  .input__date-range {
    position: relative;
    display: block;
    border-radius: 0.25rem;
    border-width: 1px;
    --tw-border-opacity: 1;
    border-color: rgb(241 241 241 / var(--tw-border-opacity));
    --tw-bg-opacity: 1;
    background-color: rgb(248 250 252 / var(--tw-bg-opacity));
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    padding-left: 1rem;
    font-size: 1rem;
    line-height: 1.8rem;
    min-height: 48px;
    --tw-shadow: 0 0 #0000;
    --tw-shadow-colored: 0 0 #0000;
    box-shadow: var(--tw-ring-offset-shadow, 0 0 #0000),
      var(--tw-ring-shadow, 0 0 #0000), var(--tw-shadow);
    padding-right: 50px;
    .mat-datepicker-toggle {
      position: absolute;
      top: 0;
      right: 0;
    }
  }
  .input__range {
    .mat-slider.mat-accent .mat-slider-track-fill,
    .mat-slider.mat-accent .mat-slider-thumb,
    .mat-slider.mat-accent .mat-slider-thumb-label {
      background-color: var(--primary-color);
    }
    mat-slider {
      width: 100%;
      padding: 0;
      height: 24px;
      .mat-slider-wrapper {
        top: 50%;
        transform: translateY(-50%);
        .mat-slider-thumb-label {
          display: flex;
          align-items: center;
          justify-content: center;
          opacity: 0.6;
          .mat-slider-thumb-label-text {
            display: inline-block;
          }
        }
      }
    }
  }
  .image_services-home {
    width: auto;
    display: block;
    cursor: pointer;
    transition: all ease-in-out 0.3s;
    &:not(:last-child) {
      margin-right: -1.7rem;
    }
    &:hover {
      /*z-index: 1;*/
      div {
        @apply shadow-[0_0_10px_rgba(0,0,0,0.4)];
      }
    }
  }
  .scrollbar_css {
    &::-webkit-scrollbar {
      width: 8px;
      height: 8px;
    }
    &::-webkit-scrollbar-track {
      box-shadow: inset 0 0 4px rgba(128, 128, 128, 0.34);
      border-radius: 10px;
    }
    &::-webkit-scrollbar-thumb {
      background: #04aa6d;
      border-radius: 10px;
    }
    &::-webkit-scrollbar-thumb:hover {
      background: #05915e;
    }
    &.min_bar {
      &::-webkit-scrollbar {
        width: 6px;
        height: 6px;
      }
      &::-webkit-scrollbar-track {
        box-shadow: inset 0 0 2px rgba(128, 128, 128, 0.34);
        border-radius: 6px;
      }
    }
  }
  .required {
    &::after {
      content: " *";
      color: red;
    }
  }

  .radio__btns {
    display: flex;
    flex-flow: wrap;
    gap: 5px;
    button {
      padding: 6px 15px;
      line-height: normal;
      border-radius: 3rem;
      border: 2px solid transparent;
      color: #000;
      font-size: 14px;
      transition: all ease-in-out 0.25s;
      &.status__new {
        color: #4990eb;
        &.active {
          border-color: #4990eb;
        }
      }
      &.status__convert {
        color: #13d213;
        &.active {
          border-color: #13d213;
        }
      }
      &.status__pending {
        color: #ffc400;
        &.active {
          border-color: #ffc400;
        }
      }
      &.status__cancel {
        color: #eb4949;
        &.active {
          border-color: #eb4949;
        }
      }
    }
    mat-radio-group {
      width: 100%;
      display: flex;
      flex-flow: wrap;
      align-items: center;
      justify-content: center;
      border-radius: 10px;
      border: 1px solid #ddd;
      background-color: #ddd;
      gap: 1px;
      overflow: hidden;
      mat-radio-button {
        flex: 1;
        background-color: #fff;
        font-size: 14px;
        .mdc-form-field {
          width: 100%;
          .mdc-label {
            padding-right: 10px;
            cursor: pointer;
            line-height: normal;
          }
        }
      }
    }
  }
  .cy__btn:not(.btn-action) {
    padding: 0.75rem 1.25rem;
    border-radius: 8px;
    display: inline-flex;
    gap: 0.5rem;
    align-items: center;
    cursor: pointer;
    appearance: button;
    margin: 0 !important;
    border: 1px solid transparent;
    &.mdc-button {
      height: auto;
      .mdc-button__label {
        display: inline-flex;
        align-items: center;
        gap: 0.5rem;
        font-weight: 400;
        font-size: 1rem;
        line-height: normal;
      }
      &.small__btn {
        .mdc-button__label {
          font-size: 14px;
        }
      }
      &.btn__primary {
        border-color: var(--primary-color);
        background-color: var(--primary-color);
        color: #fff;
      }
      &.btn__second {
        border-color: var(--second-color);
        background-color: var(--second-color);
        color: #fff;
      }
      &.btn__third {
        border-color: var(--third-color);
        background-color: var(--third-color);
        color: var(--second-color);
      }
      &.btn__error {
        border-color: $error-color;
        background-color: $error-color;
        color: #fff;
      }
      &.btn__success {
        border-color: $success-color;
        background-color: $success-color;
        color: #fff;
      }
      &.btn__warning {
        border-color: $warning-color;
        background-color: $warning-color;
        color: #fff;
      }
      &.btn__info {
        border-color: $info-color;
        background-color: $info-color;
        color: #fff;
      }
      &.btn__grad {
        border-color: linear-gradient(0deg, #eaecf0, #eaecf0);
        background: linear-gradient(0deg, #eaecf0, #eaecf0);
        color: var(--second-color);
      }
      &:disabled {
        border-color: #c9c9c9 !important;
        background-color: #c9c9c9 !important;
      }
      &.btn__outline {
        border: 1px solid #d0d5dd;
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.051);
        background-color: #fff;
      }
    }
    &:not(.mdc-button) {
      &.small__btn {
        .mdc-button__label {
          font-size: 14px;
        }
      }
      &.btn__primary {
        border-color: var(--primary-color);
        background-color: var(--primary-color);
        color: #fff;
      }
      &.btn__second {
        border-color: var(--second-color);
        background-color: var(--second-color);
        color: #fff;
      }
      &.btn__third {
        border-color: var(--third-color);
        background-color: var(--third-color);
        color: var(--second-color);
      }
      &.btn__error {
        border-color: $error-color;
        background-color: $error-color;
        color: #fff;
      }
      &.btn__success {
        border-color: $success-color;
        background-color: $success-color;
        color: #fff;
      }
      &.btn__warning {
        border-color: $warning-color;
        background-color: $warning-color;
        color: #fff;
      }
      &.btn__info {
        border-color: $info-color;
        background-color: $info-color;
        color: #fff;
      }
      &.btn__grad {
        border-color: linear-gradient(0deg, #eaecf0, #eaecf0);
        background: linear-gradient(0deg, #eaecf0, #eaecf0);
        color: var(--second-color);
      }
      &:disabled {
        border-color: #c9c9c9 !important;
        background-color: #c9c9c9 !important;
      }
      &.btn__outline {
        border: 1px solid #d0d5dd;
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.051);
        background-color: #fff;
      }
    }
  }
  modal-lead-form,
  .full__hsize {
    display: grid;
    grid-template-columns: minmax(0, 1fr);
    grid-template-rows: minmax(0, auto) minmax(0, 1fr) minmax(0, auto);
    height: 100%;
    .mdc-dialog__content {
      min-height: 100%;
      max-height: 100%;
    }
  }
  mat-stepper {
    &.stepper_not-step-header .mat-horizontal-stepper-header-container {
      display: none;
      opacity: 0;
      height: 0;
      width: 0;
      pointer-events: none;
      position: absolute;
    }
    .mat-horizontal-stepper-wrapper {
      width: 100%;
    }
    &.stepper_not-change-header mat-step-header.mat-horizontal-stepper-header {
      pointer-events: none !important;
    }
  }
  .content__tree {
    .content__tree {
      padding-top: 10px;
      padding-left: 15px;
      display: none;
    }
  }
  .content__tree-list {
    overflow: hidden;
    border-left: 1px solid #ddd;
    padding: 10px;
    .content__tree-item {
      &:not(:last-child) {
        padding-bottom: 10px;
      }
    }
    .content__btn-tree {
      &::before {
        content: "";
        display: block;
        position: absolute;
        top: 50%;
        left: 0;
        transform: translate(-100%, -50%);
        width: 100%;
        height: 1px;
        background-color: #ddd;
      }
    }
  }
  .content__btn-tree {
    display: inline-flex;
    border: 1px solid #ddd;
    border-radius: 8px;
    user-select: none;
    transition: all ease-in-out 0.25s;
    background-color: #fff;
    position: relative;
    &.active {
      border-color: var(--primary-color);
      &::before {
        background-color: var(--primary-color);
      }
      .tree__dropdown {
        color: var(--primary-color);
        span i {
          transform: rotate(90deg);
        }
      }
    }
    .checkbox__input {
      margin: 10px;
      position: relative;
      input {
        position: absolute;
        top: 0;
        left: 0;
        width: 100% !important;
        height: 100% !important;
        display: block !important;
        appearance: none;
        z-index: 1;
        opacity: 0;
        cursor: pointer;
        &:checked ~ span {
          background-color: var(--primary-color, #{$primary-color});
          &::before {
            transform: translate(-50%, -50%) scale(1);
          }
          &::after {
            transform: translate(-50%, -50%) rotate(10deg) scale(1);
          }
        }
      }
      span {
        width: 25px;
        height: 25px;
        display: block;
        border-radius: 0.4rem;
        background-color: #f1f1f1;
        transition: all ease-in-out 0.25s;
        position: relative;
        &::before {
          content: "";
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%) scale(0);
          transform-origin: center;
          background-color: var(--primary-color, #{$primary-color});
          border-radius: 4px;
          transition: all ease-in-out 0.2s;
          width: 100%;
          height: 100%;
        }
        &::after {
          content: "\2713";
          font-size: 16px;
          color: #fff;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%) rotate(10deg) scale(0);
          transform-origin: center;
          transition: all ease-in-out 0.2s;
        }
      }
    }
    .tree__dropdown {
      display: inline-flex;
      align-items: center;
      padding: 10px;
      color: var(--second-color);
      cursor: pointer;
      h5 {
        line-height: normal;
        font-size: 16px;
        font-weight: 500;
      }
      span {
        width: 22px;
        height: 22px;
        display: flex;
        align-items: center;
        justify-content: center;
        i {
          transition: all ease 0.3s;
        }
      }
    }
  }
  .mat__type-2 {
    .mat-expansion-panel-content {
      background: transparent;
    }
  }
  .mat__check-option-custom {
    .icon__multiple-check {
      order: 1;
      margin-right: 0;
      margin-left: 16px;
    }
    mat-pseudo-checkbox {
      display: none;
    }
  }
  .error__tg {
    font-size: 12px;
    color: $error-color;
  }

  .animation__item-sending {
    width: 150px;
    max-width: 100%;
    margin: 0 auto;
    display: block;
  }

  .fr-wrapper {
    div:has(a) {
      display: none;
    }
  }
  .fr-placeholder {
    line-height: 22.4px !important;
    margin-top: 0px !important;
    padding-top: 20px !important;
    padding-left: 20px !important;
    padding-right: 20px !important;
  }

  .content__progress-bar {
    width: 100%;
    position: relative;
    &.percent__white {
      .percent__progress-bar {
        color: white;
        font-weight: 400;
      }
    }
    .percent__progress-bar {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      margin: auto;
      width: fit-content;
      height: fit-content;
      display: block;
      z-index: 2;
      font-size: 12px;
      font-weight: 600;
      color: var(--second-color);
    }
    .mat-mdc-progress-bar {
      --mdc-linear-progress-active-indicator-height: 20px;
      --mdc-linear-progress-track-height: 20px;
    }
  }
}

.swal2-popup.swal2-modal.alert_table-swal {
  width: auto;
  max-width: 100%;
  .ctt_table {
    table {
      font-size: 1.075rem !important;
      vertical-align: middle !important;
      margin-bottom: 1rem;
      color: #181c32;
      border-color: #eff2f5;
      caption-side: bottom;
      border-collapse: collapse;
      thead,
      tbody,
      tfoot,
      tr,
      td,
      th {
        border-color: inherit;
        border-style: solid;
        border-width: 0;
      }
      thead {
        vertical-align: bottom;
        color: #b5b5c3 !important;
        font-weight: 600 !important;
        font-size: 0.95rem !important;
        text-transform: uppercase !important;
        tr {
          border-bottom-width: 1px;
          border-bottom-style: dashed;
          border-bottom-color: #eff2f5;
        }
      }
      // &:not(:first-child) { border-color: transparent; border-width: 0; border-style: none; }
      td,
      th {
        padding-top: 1.25rem;
        padding-bottom: 1.25rem;
        padding: 0.75rem 0.75rem;
        &:first-child {
          min-width: 55px;
        }
        &:not(:first-child) {
          min-width: 100px;
        }
        &:nth-child(3) {
          min-width: 150px;
        }
        &:nth-child(7) {
          min-width: 200px;
        }
      }
      tr {
        &.danger {
          background-color: #fee2e2;
        }
        &.success {
          background-color: #dcfce7;
        }
      }
      tr:last-child,
      th:last-child,
      td:last-child {
        padding-right: 0;
      }
      tr:first-child,
      th:first-child,
      td:first-child {
        padding-left: 0;
      }
      .table.table-row-dashed tr {
        border-bottom-width: 1px;
        border-bottom-style: dashed;
        border-bottom-color: #eff2f5;
      }
      tfoot tr:last-child,
      tbody tr:last-child {
        border-bottom: 0 !important;
      }
    }
  }
  .two_line-hidden {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}

.items_checkbox-roles:not(.enabled-items) {
  pointer-events: none;
  input {
    background-color: rgb(243, 244, 246);
  }
}
.filter_option {
  position: sticky;
  top: -8px;
  z-index: 1;
  opacity: 1;
  margin-top: -8px;
  input {
    width: 100%;
    display: block;
    padding: 10px;
    border-bottom: 1px solid rgb(221, 221, 221) !important;
    outline: none;
  }
}
input[uppercase] {
  text-transform: uppercase;
}
.custom__mat-select {
  &[multiple] {
    padding: 0;
    border: 0;
    outline: none;
    outline-offset: 0;
    box-shadow: none;
  }
  &.bg__white .mat-mdc-select-trigger {
    background-color: #fff;
  }
  .mat-mdc-select-trigger {
    @apply m-0 w-full bg-slate-50 rounded py-2 px-4 text-base border shadow-none border-[#F1F1F1] focus:border-[#F1F1F1] focus:ring-0;
  }
}

.me {
  padding-left: 15px;
  background-size: cover;
}
.container-dropdown {
  margin-top: 5px;
  align-items: center;
  display: flex;
  height: auto;
  justify-content: center;
  width: auto;
}
.email-drop {
  background: #dd042b;
  color: white;
  font-weight: bold;
  border-radius: 16px;
  height: 32px;
  overflow: hidden;
  position: relative;
  width: 240px;
  -webkit-tap-highlight-color: transparent;
  transition: width 300ms cubic-bezier(0.4, 0, 0.2, 1),
    height 300ms cubic-bezier(0.4, 0, 0.2, 1),
    box-shadow 300ms cubic-bezier(0.4, 0, 0.2, 1),
    border-radius 300ms cubic-bezier(0.4, 0, 0.2, 1);
}
.email-drop:not(.expand) {
  cursor: pointer;
}
.email-drop:not(.expand):hover {
  background: #c2c0c2;
}
.from {
  position: absolute;
  transition: opacity 200ms 100ms cubic-bezier(0, 0, 0.2, 1);
}
.from-contents {
  display: flex;
  flex-direction: row;
  transform-origin: 0 0;
  transition: transform 300ms cubic-bezier(0.4, 0, 0.2, 1);
  text-align: center;
}
.to {
  opacity: 0;
  position: absolute;
  transition: opacity 100ms cubic-bezier(0.4, 0, 1, 1);
}
.to-contents {
  transform: scale(0.55);
  transform-origin: 0 0;
  transition: transform 300ms cubic-bezier(0.4, 0, 0.2, 1);
}
.avatar {
  border-radius: 12px;
  height: 24px;
  left: 6px;
  position: relative;
  top: 4px;
  width: 24px;
}
.name {
  font-size: 14px;
  line-height: 32px;
  margin-left: 20px;
}
.top-drop {
  background: #dd042b;
  display: flex;
  flex-direction: row;
  height: 70px;
  transition: height 300ms cubic-bezier(0.4, 0, 0.2, 1);
  width: 300px;
}
.avatar-large {
  border-radius: 21px;
  height: 42px;
  margin-left: 12px;
  position: relative;
  top: 14px;
  width: 42px;
}
.name-large {
  color: #efd8ef;
  font-size: 16px;
  line-height: 70px;
  margin-left: 20px;
}
.x-touch {
  align-items: center;
  align-self: center;
  cursor: pointer;
  display: flex;
  height: 50px;
  justify-content: center;
  margin-left: auto;
  width: 50px;
}
.x {
  background: #fb9ab9;
  border-radius: 10px;
  height: 20px;
  position: relative;
  width: 20px;
}
.x-touch:hover .x {
  background: #ca748f;
}
.line1 {
  background: #dd042b;
  height: 12px;
  position: absolute;
  transform: translateX(9px) translateY(4px) rotate(45deg);
  width: 2px;
}
.line2 {
  background: #dd042b;
  height: 12px;
  position: absolute;
  transform: translateX(9px) translateY(4px) rotate(-45deg);
  width: 2px;
}
.bottom-drop {
  background: #fff;
  color: #444247;
  font-size: 14px;
  height: 380px;
  padding-top: 5px;
  width: 300px;
  overflow-y: auto;
  max-height: 310px;
}
.row-items {
  align-items: center;
  display: flex;
  flex-direction: row;
  height: 60px;
}
.twitter {
  margin-left: 16px;
  height: 30px;
  position: relative;
  top: 0px;
  width: 30px;
}
.medium {
  height: 30px;
  margin-left: 16px;
  position: relative;
  width: 30px;
}
.link {
  margin-left: 16px;
}
.link a {
  color: #444247;
  text-decoration: none;
}
.link a:hover {
  color: #777579;
}
.email-drop.expand {
  border-radius: 6px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1), 0 6px 6px rgba(0, 0, 0, 0.16);
  height: 380px;
  width: 300px;
}
.expand .from {
  opacity: 0;
  transition: opacity 100ms cubic-bezier(0.4, 0, 1, 1);
}
.expand .from-contents {
  transform: scale(1.91);
}
.expand .to {
  opacity: 1;
  transition: opacity 200ms 100ms cubic-bezier(0, 0, 0.2, 1);
}
.expand .to-contents {
  transform: scale(1);
}

@keyframes spinner {
  to {
    transform: rotate(360deg);
  }
}

.spinner:after {
  content: "";
  box-sizing: border-box;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 40px;
  height: 40px;
  margin-top: -20px;
  margin-left: -20px;
  border-radius: 50%;
  border: 5px solid rgba(180, 180, 180, 0.6);
  border-top-color: rgba(0, 0, 0, 0.6);
  animation: spinner 0.6s linear infinite;
}

.mdc-text-field--filled:not(.mdc-text-field--disabled) {
  --mdc-filled-text-field-container-color: transparent;
}
.mat-mdc-form-field:hover .mat-mdc-form-field-focus-overlay,
.mat-mdc-form-field.mat-focused .mat-mdc-form-field-focus-overlay {
  opacity: 0.02;
}
mat-tooltip-component {
  font-size: 13px;
}
mat-header-cell {
  color: var(--second-color);
}
.society {
  background-color: rgba(#000, 0.025);
  color: #000;
  border: 1px solid rgba(#000, 0.25);
  font-size: 0.75rem;
  line-height: normal;
  padding: 0.25rem 0.5rem;
  border-radius: 5rem;
  text-overflow: ellipsis;
  white-space: nowrap;

  &.not_bullet::before {
    display: none;
  }
  &::before {
    content: "";
    width: 5px;
    height: 5px;
    margin-right: 5px;
    border-radius: 50%;
    background-color: currentColor;
    display: inline-block;
    vertical-align: middle;
  }
  &.s__platino {
    display: flex;
    align-items: center;
    background-color: rgba(#344054, 0.1);
    color: #344054;
    border-color: rgba(#344054, 0.25);
    width: fit-content;
  }
  &.s__celeste {
    display: flex;
    align-items: center;
    background-color: rgba(var(--primary-color), 0.1);
    color: var(--primary-color);
    border-color: rgba(var(--primary-color), 0.25);
    width: fit-content;
  }
  &.s__dorado {
    display: flex;
    align-items: center;
    background-color: rgba(#ddaf00, 0.1);
    color: #ddaf00;
    border-color: rgba(#ddaf00, 0.25);
    width: fit-content;
  }
}

.status_id_1 {
  background-color: rgba(var(--rgb-info-color), 0.2);
  color: var(--info-color);
  border-color: var(--info-color);
} // Pendiente
.status_id_2 {
  background-color: rgba(var(--rgb-warning-color), 0.2);
  color: var(--warning-color);
  border-color: var(--warning-color);
} // Analizando
.status_id_3 {
  background-color: rgba(var(--rgb-error-color), 0.2);
  color: var(--error-color);
  border-color: var(--error-color);
} // No interezado
.status_id_4 {
  background-color: var(--success-color);
  color: white;
  border-color: var(--success-color);
} // Convertido
.status_id_6 {
  background-color: rgba(var(--rgb-info-color), 0.3);
  color: var(--info-color);
  border-color: var(--info-color);
} // En espera de decisión
.status_id_7 {
  background-color: rgba(var(--rgb-success-color), 0.2);
  color: var(--success-color);
  border-color: var(--success-color);
} // Interesado para más adelante

.etq-socio {
  padding: 5px 12px;
  border-radius: 30px;
  font-size: 13px;
  font-weight: 700;
  font-family: "Inter", sans-serif;
}
.etq-celeste {
  background-color: rgba(var(--primary-color), 0.1);
  color: var(--primary-color);
}
.etq-dorado {
  background-color: rgba(#ddaf00, 0.1);
  color: #ddaf00;
}
.etq-platino {
  background-color: rgba(#344054, 0.1);
  color: #344054;
}

.not__asign {
  background-color: rgba(#e74b4b, 0.025);
  color: #e74b4b;
  font-size: 0.85rem;
  line-height: normal;
  padding: 0.4rem 0.8rem;
  border-radius: 5rem;
}
.status {
  background-color: rgba($second-color, 0.055);
  color: var(--second-color);
  font-size: 0.75rem;
  line-height: normal;
  padding: 0.4rem 0.8rem;
  border-radius: 5rem;
  &.status__send,
  &.status__success {
    color: $success-color;
    background-color: rgba($success-color, 0.055);
  }
  &.status__sending {
    color: $info-color;
    background-color: rgba($info-color, 0.055);
  }
  &.status__cancel,
  &.status__error {
    color: $error-color;
    background-color: rgba($error-color, 0.055);
  }
}

.user-custom {
  display: grid;
  grid-template-columns: minmax(0, max-content) minmax(0, auto);
  gap: 10px;
  padding: 5px;
  align-items: center;
  &:has(.phone) {
    align-items: flex-start;
  }
  .img-custom {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    overflow: hidden;
    &.default_img {
      border-radius: 0;
      img {
        width: auto;
        height: auto;
        max-width: 100%;
        max-height: 100%;
        object-fit: contain;
        display: block;
        margin: auto;
      }
    }
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      object-position: center;
    }
  }
  .info-custom {
    h5,
    p {
      font-family: var(--font-subtext);
      font-size: 14px;
      max-width: 100%;
      // overflow: hidden;
      // text-overflow: ellipsis;
      // white-space: nowrap;
    }
    h5 {
      color: var(--second-color);
      font-weight: 600;
      font-size: 14px;
      line-height: normal;
      // text-transform: uppercase;
    }
    p {
      color: #979797;
      font-weight: 300;
      line-height: normal;
      &.phone {
        margin-top: 2px;
        font-weight: 500;
        color: var(--primary-color);
      }
    }
  }
}

.user-custom--big {
  .img-custom {
    width: 80px;
    height: 80px;
  }

  .info-custom {
    h5 {
      font-size: 18px;
    }
    p {
      font-size: 16px;
      color: rgb(52 64 84);
    }
  }
}

.data_loader {
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 0.5rem;
  .btn__roller {
    display: inline-block;
    margin-left: 0;
    margin-right: 0;
    width: 25px;
    position: relative;
    --color-spin: var(--primary-color);
    --color2-spin: var(--second-color);
    --size-spin: 3px;
    &::before {
      content: "";
      display: block;
      padding-bottom: 100%;
    }
  }
}

.content__loader_min {
  width: auto;
  display: block;
  max-width: 100%;
  text-align: center;
  padding: 1rem;
}
.loader__spin {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  animation: rotate 1s linear infinite;
  &::before {
    content: "";
    box-sizing: border-box;
    position: absolute;
    inset: 0px;
    border-radius: 50%;
    border: var(--size-spin, 5px) solid var(--color-spin, #fff);
    animation: prixClipFix 2s linear infinite;
  }
}
@keyframes rotate {
  100% {
    transform: rotate(360deg);
  }
}

@keyframes prixClipFix {
  0% {
    clip-path: polygon(50% 50%, 0 0, 0 0, 0 0, 0 0, 0 0);
  }
  25% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 0, 100% 0, 100% 0);
  }
  50% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 100% 100%, 100% 100%);
  }
  75% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 100%);
  }
  100% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 0 100%, 0 0);
  }
}

.loader__spin2 {
  width: 100%;
  height: 100%;
  border-radius: 50%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  animation: rotate2 1s linear infinite;
  &::before,
  &::after {
    content: "";
    box-sizing: border-box;
    position: absolute;
    inset: 0px;
    border-radius: 50%;
    border: var(--size-spin, 5px) solid var(--color-spin, #fff);
    animation: prixClipFix2 2s linear infinite;
  }
  &::after {
    transform: rotate3d(90, 90, 0, 180deg);
    border-color: var(--color2-spin, #000);
  }
}

@keyframes rotate2 {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

@keyframes prixClipFix2 {
  0% {
    clip-path: polygon(50% 50%, 0 0, 0 0, 0 0, 0 0, 0 0);
  }
  50% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 0, 100% 0, 100% 0);
  }
  75%,
  100% {
    clip-path: polygon(50% 50%, 0 0, 100% 0, 100% 100%, 100% 100%, 100% 100%);
  }
}

.content__users {
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  gap: 10px;
  .user-custom {
    cursor: pointer;
    border-radius: 10px;
    border: 1px solid transparent;
    &:hover {
      background-color: rgba($second-color, 0.025);
    }
    &.active {
      border-color: var(--second-color);
    }
  }
}
.next_action {
  font-size: 0.85rem;
  line-height: normal;
  padding: 0.4rem 0.8rem;
  border-radius: 5rem;
  color: #13d213;
  text-decoration: underline;
  text-decoration-thickness: 2px;
  font-weight: 500;
}

.tabs-btn {
  button {
    padding: 10px 16px;
    border-radius: 10px 10px 0px 0px;
    background-color: rgb(220 220 220);
    color: var(--second-color);
    font-weight: 700;

    &:hover {
      color: white;
      background-color: var(--second-color);
    }
  }

  .active-tab {
    color: white;
    background-color: var(--second-color);
  }
}

.title-tabs {
  font-size: 18px;
  font-weight: 700;
  font-family: "Inter", sans-serif;
  margin-bottom: 10px;
  color: var(--second-color);
}

.title-list {
  font-size: 16px;
  font-weight: 700;
  font-family: "Inter", sans-serif;
  color: var(--second-color);
  max-width: 1200px;
  margin: 0px auto;
  overflow: hidden;
  text-overflow: ellipsis;
}

.notifications {
  max-width: 1200px;
  margin: 0px auto;
  li {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1rem;
    background-color: #f2f2f2;
    padding: 14px;
    border-radius: 12px;
    margin-bottom: 8px;
    flex-wrap: wrap;

    .ls-phone,
    .ls-socios,
    .ls-send,
    app-user-custom {
      flex: 1 0 200px;
    }

    .ls-date {
      flex: 0.5 0 100px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      font-size: 15px;
      font-family: "Inter", sans-serif;
      font-weight: 400;
      color: #172839;
      span {
        &:first-child {
          color: rgb(235, 62, 62);
        }
      }
    }

    .ls-socios {
      span {
        font-family: "Inter", sans-serif;
        font-weight: 400;
        font-size: 16px;
        display: flex;
        align-items: center;
        gap: 8px;
        justify-content: center;
        &::before {
          content: "";
          width: 7px;
          height: 7px;
          border-radius: 50%;
        }
      }
      .ls-celeste {
        color: var(--primary-color);
        &::before {
          background-color: var(--primary-color);
        }
      }
      .ls-dorado {
        color: #ddaf00;
        &::before {
          background-color: #ddaf00;
        }
      }
      .ls-platino {
        color: #344054;
        &::before {
          background-color: #344054;
        }
      }
    }
    .ls-send {
      text-align: center;
      span,
      a {
        font-size: 16px;
        font-family: "Inter", sans-serif;
        font-weight: 600;
      }

      span {
        color: #04aa6d;
      }
      a {
        color: var(--second-color);
        text-decoration: underline;
        cursor: pointer;
        &:hover {
          color: var(--primary-color);
        }
      }
    }

    .ls-phone {
      color: var(--second-color);
      text-align: center;
    }
  }
}

.grid-sales {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(500px, 1fr));
  grid-gap: 1rem;
  margin-top: 16px;

  .item-sales {
    border: 1px solid rgb(223, 223, 223);
    border-radius: 15px;
    box-shadow: 3px 6px 12px 0px rgb(179 179 179 / 32%);
    padding: 20px;
    background-color: white;
    /* height: max-content;
        max-height: 450px; */

    ul {
      li {
        display: grid;
        grid-template-columns: 40% 40% 20%;
        gap: 10px;
        place-items: baseline;
        align-items: center;

        .prg-text {
          font-family: "Inter", sans-serif;
          font-size: 17px;
          display: flex;
          gap: 5px;
          align-items: center;
          justify-content: space-between;
          padding: 5px;
          span {
            color: #1367a3;
            font-weight: 700;
          }
          i {
            font-size: 12px;
          }
        }
      }
    }

    .ls--pall {
      li {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 16px;
        margin-bottom: 4px;
        width: 90%;
        margin: 0 auto;
      }
    }

    .form-sales {
      display: flex;
      gap: 12px;
      margin-top: 16px;
      margin-bottom: 16px;
      flex-wrap: wrap;

      .content-f {
        flex: 1 0 180px;
        display: flex;
        flex-direction: column;
        font-family: "Inter", sans-serif;

        label {
          color: #000;
        }

        input,
        select {
          border-radius: 10px;
          border: 1px solid #bcbcbc;

          &:focus {
            outline: none !important;
            border: 1px solid #2cc0ff00;
          }
          &:focus-visible {
            outline: none !important;
          }
        }
      }
    }
  }

  .lst-paralela {
    li {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 16px;
      margin-bottom: 4px;
      width: 90%;
      margin: 0 auto;
    }
  }

  .mat-mdc-progress-bar {
    --mdc-linear-progress-active-indicator-height: 12px;
    --mdc-linear-progress-track-height: 12px;
    --mdc-linear-progress-track-shape: 0;
    border-radius: 50px;
  }
  .mat-mdc-progress-bar .mdc-linear-progress__buffer-bar {
    background-color: rgb(240, 240, 240);
  }

  .p-green .mdc-linear-progress__bar-inner {
    /* border-color: var(--mdc-linear-progress-active-indicator-color); */
    border-color: #13d213;
  }
  .p-yellow .mdc-linear-progress__bar-inner {
    border-color: #ffc400;
  }
  .p-red .mdc-linear-progress__bar-inner {
    border-color: #f4383f;
  }
  .p-gray .mdc-linear-progress__bar-inner {
    border-color: gray;
  }
}

.txt-p-green {
  color: #13d213;
}
.txt-p-yellow {
  color: #ffc400;
}
.txt-p-red {
  color: #f4383f;
}
.txt-p-gray {
  color: gray !important;
}
.txt-primary {
  color: var(--primary-color);
}

.grid-panel {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(135px, 1fr));
  grid-gap: 1rem;
  margin-top: 16px;
}
.item-panel {
  background-color: white;
  padding: 14px 22px;
  border-radius: 14px;
  box-shadow: 0 4px 24px 0 rgba(34, 41, 47, 0.1);

  p {
    font-size: 12px;
    font-family: "Inter", sans-serif;
    color: var(--second-color);
    font-weight: 700;
  }

  div {
    display: flex;
    align-items: center;
    gap: 16px;
    justify-content: center;
    span {
      font-size: 25px;
      font-weight: 700;
      color: #0c67c8;
    }
    i {
      font-size: 20px;
    }
  }
}

.panel-header {
  display: flex;
  gap: 16px;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 18px;
  flex-wrap: wrap;

  .content-input {
    display: flex;
    gap: 12px;
    align-items: center;
    color: gray;

    label {
      font-size: 14px;
      font-family: "Inter", sans-serif;
    }

    input,
    select {
      border-radius: 8px;
      border: 1px solid #bcbcbc;
      font-size: 14px;
      padding: 5px 30px 5px 10px;
    }
  }
}
.prg-text {
  font-family: "Inter", sans-serif;
  font-size: 17px;
  display: flex;
  gap: 5px;
  align-items: center;
  justify-content: space-between;
  padding: 5px;
  span,
  strong {
    color: #1367a3;
    font-weight: 700;
  }
  i {
    font-size: 12px;
  }
}

.prg-text--big {
  justify-content: flex-start !important;
  span {
    font-size: 35px;
    color: #1367a3;
    font-weight: 700;
  }
  i {
    font-size: 22px;
  }
}

.prg-text--medui {
  justify-content: flex-start !important;
  span {
    font-size: 22px;
    color: #1367a3;
    font-weight: 700;
    margin-right: 10px;
  }
  i {
    font-size: 16px;
  }
}

.etq-dashboard {
  span {
    font-size: 16px;
    color: var(--second-color);
    display: flex;
    align-items: center;
    gap: 8px;
    font-family: "Inter", sans-serif;
    font-weight: 500;

    &::before {
      content: "";
      display: block;
      min-width: 8px;
      min-height: 8px;
      border-radius: 50%;
      background-color: var(--primary-color);
    }
  }
}

.layout-card1 {
  display: grid;
  grid-template-columns: 68% 29%;
  gap: 16px;
  /* grid-auto-rows: max-content;
    height: 450px; */
}

.grid-2 {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(150px, 1fr));
  grid-gap: 1rem;

  .item-grid2 {
    border: 1px solid rgb(223, 223, 223);
    border-radius: 10px;
    box-shadow: 3px 6px 12px 0px rgb(179 179 179 / 32%);
    padding: 18px;
    background-color: white;
  }
}

.bg-grid {
  border: 1px solid rgb(223, 223, 223);
  border-radius: 10px;
  box-shadow: 3px 6px 12px 0px rgba(179, 179, 179, 0.32);
  padding: 20px;
  background-color: white;
}

.btn-circle {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: #eaf0f6;
  border: 1px solid #53769c;
  font-size: 20px;
  color: #53769c;
}
.hover-btn {
  cursor: pointer;
  &:hover .btn-circle,
  &:hover p {
    color: var(--primary-color) !important;
    border-color: var(--primary-color);
    background-color: rgba(var(--primary-color), 0.1);
  }

  .note-icon {
    color: #192b3d;
    border: 1px solid #192b3d;
    background-color: rgba(#192b3d, 0.1);
    & ~ p {
      color: #192b3d;
    }
  }
  .email-icon {
    color: #ff3126;
    border: 1px solid #ff3126;
    background-color: rgba(#ff3126, 0.1);
    & ~ p {
      color: #ff3126;
    }
  }
  .call-icon {
    color: #3f91ff;
    border: 1px solid #3f91ff;
    background-color: rgba(#3f91ff, 0.1);
    & ~ p {
      color: #3f91ff;
    }
  }
  .what-icon {
    color: #5fca2d;
    border: 1px solid #5fca2d;
    background-color: rgba(#5fca2d, 0.1);
    & ~ p {
      color: #5fca2d;
    }
  }
  .task-icon {
    color: #bb004d;
    border: 1px solid #bb004d;
    background-color: rgba(#bb004d, 0.1);
    & ~ p {
      color: #bb004d;
    }
  }
  .reu-icon {
    color: #a9acaf;
    border: 1px solid #a9acaf;
    background-color: rgba(#a9acaf, 0.1);
    & ~ p {
      color: #a9acaf;
    }
  }
}

.mat-expansion-panel:not([class*="mat-elevation-z"]) {
  box-shadow: none !important;
  border-bottom: 1px solid #eaeaea;
  border-radius: 0px !important;
  padding-bottom: 8px;
}
.mat-expansion-indicator::after {
  border-color: #101828;
}
.mat-expansion-panel-content {
  background-color: #f8fafc;
}

input:focus-visible {
  outline: none !important;
}

.lista-acordion {
  padding: 12px 0px;
  list-style: disc;
  li {
    font-family: "Inter", sans-serif;
    color: #53769c;
    text-align: justify;
    font-size: 14px;
    &::marker {
      color: #53769c !important;
      font-size: 20px;
    }
  }
}

.grid-metas {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  grid-gap: 1rem;
}
.dContents {
  display: contents;
}
.type-Client {
  font-family: "Inter", sans-serif;
  color: #53769c;
  border-radius: 50px;
  border: 1px solid #53769c;
  padding: 5px 11px;
  font-weight: 700;
  font-size: 12px;
}

.modal-reu {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 180px;
  gap: 16px;
}

.content-smj {
  width: 100%;
  padding-bottom: 12px;
  margin-bottom: 12px;
  border-bottom: 1px solid rgb(228, 228, 228);

  &:last-child {
    border-color: transparent;
  }
}

.item-cat {
  flex: 1 0 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  &:first-child {
    flex: 0.5;
  }
}

.reading-block {
  top: -5px;
  font-weight: 500 !important;
  left: 2px;
  color: var(--second-color);
  font-size: 22px !important;
}

.sidebar__menu {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.25rem;
  position: relative;
  .sidebar__menu-item {
    padding: 0 1rem;
    width: 100%;
    .sidebar__menu-item-content {
      position: relative;
      width: 100%;
      overflow: hidden;
      &.active {
        background-color: rgba(0, 0, 0, 0.02);
        border-radius: 10px;
        & > .sidebar__menu-item-content-title {
          .sidebar__menu-item-dropdown {
            i {
              transform: rotate(180deg);
            }
          }
        }
      }
      .sidebar__menu-item-content-title {
        padding: 0.75rem 0 0.75rem 0.75rem;
        border-radius: 0.5rem;
        width: 100%;
        color: var(--second-color);
        display: flex;
        align-items: center;
        &:has(a) {
          padding: 0;
          &:hover {
            background-color: rgba(0, 0, 0, 0.035);
          }
          a {
            padding: 0.75rem;
            font-family: var(--font-title);
            font-weight: 300;
            font-size: 1.125rem;
          }
          .sidebar__menu-item-dropdown {
            background-color: rgba(0, 0, 0, 0.035);
            &:hover {
              background-color: var(--primary-color);
              color: white;
            }
          }
        }
        &:has(a.active) {
          background-color: var(--second-color);
          color: white;
          a {
            font-weight: 400;
          }
        }
        &:has(.sidebar__menu-item-dropdown) {
          padding: 0;
          h5 {
            padding: 0.75rem;
            cursor: pointer;
          }
          i {
            transform-origin: center;
            transition: all 0.25s;
          }
        }
        h5,
        a {
          flex: 1;
          line-height: normal;
          display: flex;
          align-items: center;
          font-weight: 500;
          font-size: 1rem;
          i {
            min-width: 30px;
            width: auto;
            display: flex;
            align-items: center;
            text-align: center;
          }
          span {
            flex: 1;
            text-align: left;
          }
        }
        h5 {
          font-weight: 600;
          color: #222;
        }
        .sidebar__menu-item-dropdown {
          width: 30px;
          height: 30px;
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 6px;
          outline: none;
          &:hover {
            background-color: rgba(0, 0, 0, 0.035);
          }
        }
      }
      .sidebar__menu-submenu {
        padding: 0 0 0 1rem;
        .sidebar__menu-item {
          padding: 0;
        }
      }
    }
  }
}
